<template>
  <!-- 库存管理 -->
  <div class="inventoryManagement">
    <!-- 搜索筛选 -->
    <FormSearch :getdata="getdata" :form-inline="formInline" :search-form-item-arr="formItemArr" />
    <!-- 表格  分页 -->
    <Table
      :item-data="itemData"
      :list-data="listData"
      :loading="loading"
    >
      <template slot="dynamicInsert">
        <el-table-column type="expand">
          <template slot-scope="props">
            <table class="tableBox">
              <tr>
                <td>钢卷号</td>
                <td>仓库编号</td>
                <td>货位号</td>
                <td>货位描述</td>
                <td>品名</td>
                <td>件重</td>
                <!-- <td>入库数量</td> -->
                <td>入库数量</td>
              </tr>
              <tr v-for="(item, index) in props.row.wmsInventorydetailRespList" :key="index">
                <td>{{ item.detailCoilNo }}</td>
                <td>{{ item.detailWarehouseNo }}</td>
                <td>{{ item.detailLocationNo }}</td>
                <td>{{ item.detailLocationDesc }}</td>
                <td>{{ item.detailGoodsName }}</td>
                <td>{{ item.detailWeights }}</td>
                <!-- <td>{{ item.detailInvAmount }}</td> -->
                <td>{{ item.detailInvWeight }}</td>
              </tr>
              <tr v-if="props.row.wmsInventorydetailRespList.length===0">
                <td colspan="8">
                  暂无数据！
                </td>
              </tr>
            </table>
          </template>
        </el-table-column>
      </template>
    </Table>
    <!-- 分页 -->
    <Pagination :params="formInline" :total="total" :in-article="listData.length" :get-data-list="getdata" />
  </div>
</template>

<script>
import FormSearch from '@/components/FormSearch'
import Table from '@/components/Table'
import Pagination from '@/components/Pagination'
import { inventoryPage } from '@/api/common'
export default {
  components: { FormSearch, Table, Pagination },
  data() {
    return {
      cmpNameArr: [],
      editFormData: {},
      itemData: [
        { label: '库存编号', prop: 'inventoryNo', width: 180 },
        { label: '仓库名称', prop: 'warehouseName', width: 180 },
        { label: '货主名称', prop: 'custName', width: 180 },
        { label: '品名', prop: 'goodsName', width: 120 },
        { label: '材质', prop: 'materials', width: 120 },
        { label: '规格', prop: 'specs', width: 120 },
        { label: '产地 ', prop: 'productions', width: 120 },
        // { label: '库存数量', prop: 'invAmount', width: 100 },
        { label: '库存数量', prop: 'invWeight', width: 100 },
        { label: '可用量', prop: 'avlbAmount', width: 100 },
        { label: '可用数量', prop: 'avlbWeight', width: 100 }
      ],
      formItemArr: [
        // { type: 'input', label: '货主名称', value: 'custName' },
        { type: 'input', label: '品名', value: 'goodsName' }
        // { type: 'select', label: '状态', value: 'state', pLabel: 'label', pValue: 'value', child: [{ value: '01', label: '有效' }, { value: '02', label: '禁用' }] }
      ],
      loading: false,
      total: 0,
      listData: [],

      formInline: {
        pageSize: 10,
        pageNum: 1
      }
    }
  },
  methods: {
    // 获取列表数据
    getdata(type) {
      this.formInline.custId = JSON.parse(localStorage.getItem('userInfoWms')).cmpId
      if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          pageNum: 1
        }
      } else if (!type) {
        this.formInline.pageNum = 1
      }
      inventoryPage(this.formInline, res => {
        this.listData = [...res.data.pageData]
        this.total = res.data.total
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.inventoryManagement {
  .tableBox {
    margin-left: 92px;
    text-align: center;
    width: 1200px;
    border-collapse: collapse;

    td {
      width: 150px;
      height: 40px;
      border: 1px solid #ededed;
    }
  }

  .childrenTable {
    margin-left: 100px;
  }

  .buttonCombination {
    margin-top: 20px;
    text-align: right;
  }
}
</style>
